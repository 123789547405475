import React, { Component } from 'react';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';
import * as fetchFunction from './Functions/FetchFunctions';

export class OrderDetails extends Component {
    static displayName = OrderDetails.name;

    render() {
        const trimmed_trailer = this.props.order.trailer_id ? this.props.order.trailer_id.trim() : '';
        const current_temp =
            this.props.trailers && this.props.order
                ? this.props.trailers.find((s) => s.name === trimmed_trailer)
                    ? this.props.trailers.find((s) => s.name === trimmed_trailer).setTemp
                        ? this.props.trailers.find((s) => s.name === trimmed_trailer).setTemp
                        : '-'
                    : '-'
                : '-';

        const return_temp =
            this.props.trailers && this.props.order
                ? this.props.trailers.find((s) => s.name === trimmed_trailer)
                    ? this.props.trailers.find((s) => s.name === trimmed_trailer).returnTemp
                        ? this.props.trailers.find((s) => s.name === trimmed_trailer).returnTemp
                        : '-'
                    : '-'
                : '-';

        const temp_mode =
            this.props.trailers && this.props.order
                ? this.props.trailers.find((s) => s.name === trimmed_trailer)
                    ? this.props.trailers.find((s) => s.name === trimmed_trailer).tempMode
                        ? this.props.trailers.find((s) => s.name === trimmed_trailer).tempMode
                        : '-'
                    : '-'
                : '-';

        const temp_min = this.props.order.temperature_min ? this.props.order.temperature_min : 'none';
        const temp_max = this.props.order.temperature_max ? this.props.order.temperature_max : 'none';
        const stops = this.props.order.stops ? this.props.order.stops : [];

        //#region Flags
        const flag_icon = (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="#e45b5e"
                class="bi bi-flag-fill"
                viewBox="0 0 13 13"
                role="graphics-symbol img"
            >
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
            </svg>
        );

        const temp_flag = (temp) =>
            this.props.order.temperature_max && this.props.order.temperature_min
                ? this.props.order.temperature_min - 3 > temp || temp > this.props.order.temperature_max + 3
                    ? flag_icon
                    : null
                : null;

        const temp_flag_label = (temp, type) =>
            this.props.order.temperature_min && this.props.order.temperature_max
                ? this.props.order.temperature_min - 3 > temp || temp > this.props.order.temperature_max + 3
                    ? `${type} temp is outside the order temp threshold.`
                    : null
                : null;
        //#endregion Flags

        const row = (field, value) => (
            <div className="d-flex flex-row justify-content-between py-2">
                <strong className="w-25 pe-1">{field}:</strong>
                <label className="w-75">{value}</label>
            </div>
        );

        const date_range = (early, late) => (
            <div className="d-flex flex-column justify-content-start w-100">
                <label>{early ? fetchFunction.parseUTCDatetime(early) : 'Unknown'}</label>
                <label>{late ? fetchFunction.parseUTCDatetime(late) : null}</label>
            </div>
        );

        //#region Temp Divs
        const order_temp_div = (
            <div className="w-100">
                {' '}
                <div className="pe-1 d-flex flex-row justify-content-start">
                    {this.props.order.temperature_min ? <bdi>{this.props.order.temperature_min}</bdi> : null}
                    {this.props.order.temperature_max ? <bdi className="px-2">to</bdi> : <bdi className="pe-1">-</bdi>}

                    {this.props.order.temperature_max ? <bdi>{this.props.order.temperature_max}</bdi> : null}
                    {(current_temp !== '-' || return_temp !== '-') &&
                    !this.props.order.temperature_min &&
                    this.props.can_track === 'Y' &&
                    !temp_mode.toUpperCase().includes('DEFROST')
                        ? flag_icon
                        : null}
                </div>
            </div>
        );

        const set_temp_div = temp_mode.toUpperCase().includes('DEFROST') ? (
            <div className="w-100 opacity-75">Defrost</div>
        ) : (
            <div className="w-100" title={temp_flag_label(current_temp === '-' ? -9999 : current_temp, 'Current set')}>
                <bdi className="pe-1">{current_temp}</bdi>
                {temp_flag(current_temp === '-' ? -9999 : current_temp)}
            </div>
        );

        const return_temp_div = (
            <div className="w-100" title={temp_flag_label(return_temp === '-' ? -9999 : return_temp, 'Current return')}>
                <bdi className="pe-1">{return_temp}</bdi>
                {temp_flag(return_temp === '-' ? -9999 : return_temp)}
            </div>
        );
        //#endregion Temp Divs

        const order_details = this.props.order ? (
            <div className="d-flex flex-column ps-3">
                {row('Order #', this.props.order.id)}
                {stops.length > 0 ? row('Shipper', stops[0].location_name) : row('Shipper', 'Unknown')}
                {stops.length > 0
                    ? row('Pickup Date', date_range(stops[0].sched_arrive_early, stops[0].sched_arrive_late))
                    : row('Pickup Date', 'Unknown')}
                {stops.length > 0
                    ? row(
                          'Delivery Date',
                          date_range(
                              stops[stops.length - 1].sched_arrive_early,
                              stops[stops.length - 1].sched_arrive_late,
                          ),
                      )
                    : row('Delivery Date', 'Unknown')}
                {row('Tractor #', this.props.order.tractor_id)}
                {row('Trailer #', this.props.order.trailer_id)}
                {row('Order Temp', order_temp_div)}
                {this.props.can_track === 'Y' ? row('Set Temp', set_temp_div) : null}
                {this.props.can_track === 'Y' ? row('Return Temp', return_temp_div) : null}
            </div>
        ) : (
            <div className="p-3">
                <Alert className="text-center">Loading...</Alert>
            </div>
        );

        return (
            <Card style={{ fontSize: '0.67rem', height: '100%', width: '100%' }}>
                <Container>
                    <Row className="d-flex flex-row justify-content-between px-3 pt-3 ">
                        <Col lg={4} sm={12} className="d-flex flex-row justify-content-start p-0">
                            <h6 className="align-self-center py-1 m-0">Order Details</h6>
                        </Col>
                        <Col lg={8} sm={12} className="d-flex flex-row justify-content-end p-0" />
                    </Row>
                </Container>
                <hr className="mt-2 mb-0" />
                {order_details}
            </Card>
        );
    }
}
