import { addHours, format } from 'date-fns';
import React, { Component } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import * as fetchFunction from './Functions/FetchFunctions';
export class Tracking extends Component {
    static displayName = Tracking.name;
    constructor(props) {
        super(props);
        this.state = {
            tracking_type: 'tractor',
            selected_coordinates: null,
        };
    }

    render() {
        const { tracking_type, selected_coordinates } = this.state;

        const google_link = (lat, long) => `${process.env.REACT_APP_GOOGLE_URL}${lat},${long}`;
        const tractor_id = this.props.sp_order ? this.props.sp_order.tractor_id : '';
        const trailer_id = this.props.sp_order ? this.props.sp_order.trailer_id : '';

        const current_trailer =
            this.props.trailers && trailer_id ? this.props.trailers.find((s) => s.name === trailer_id) : null;

        const current_tractor =
            this.props.tractors && tractor_id ? this.props.tractors.find((s) => s.name === tractor_id) : null;

        const no_tracking_alert = (asset) =>
            this.props.can_track === 'Y' ? (
                <div className="p-2">
                    <Alert variant="danger">
                        No tracking information can be found in Samsara for trailer:
                        <bdi className="ps-1" style={{ fontWeight: 500 }}>
                            {asset ? asset : 'None Assigned'}
                        </bdi>
                    </Alert>
                </div>
            ) : null;

        const map_iframe = (lat, long, asset) =>
            lat && long ? (
                <iframe
                    title="Tractor Tracking"
                    style={{ width: '100%', height: '100%' }}
                    className="rounded border"
                    loading="lazy"
                    allowfullscreen
                    referrerpolicy="no-referrer-when-downgrade"
                    src={google_link(lat, long)}
                />
            ) : (
                no_tracking_alert(asset)
            );

        const tracking_map = (displayed_location, displayed_time, map) => (
            <div className="p-2 d-flex flex-column justify-content-center">
                <div className="d-flex flex-column justify-content-between">
                    <small style={{ fontSize: '0.8rem', fontWeight: '600' }}>{displayed_location}</small>
                    <small className="pb-1 opacity-75" style={{ fontSize: '0.8rem', fontWeight: '600' }}>
                        {displayed_time
                            ? `Last Updated: ${format(
                                  addHours(new Date(fetchFunction.parseToDatetime(displayed_time)), -5),
                                  'MM-dd-yyyy H:mm',
                              )}`
                            : ''}
                    </small>
                </div>
                <div className="d-flex flex-row justify-content-center">{map}</div>
            </div>
        );

        //#region Tractor Tracking

        const latitude_tractor = current_tractor
            ? current_tractor.locations
                ? current_tractor.locations.length > 0
                    ? current_tractor.locations[0].latitude
                    : ''
                : ''
            : '';
        const longitude_tractor = current_tractor
            ? current_tractor.locations
                ? current_tractor.locations.length > 0
                    ? current_tractor.locations[0].longitude
                    : ''
                : ''
            : '';
        const locationTime_tractor = current_tractor
            ? current_tractor.locations
                ? current_tractor.locations.length > 0
                    ? current_tractor.locations[0].time
                    : ''
                : ''
            : '';
        const reverse_location_tractor = current_tractor
            ? current_tractor.locations
                ? current_tractor.locations.length > 0
                    ? current_tractor.locations[0].reverseGeo.formattedLocation
                    : ''
                : ''
            : '';

        const tractor_tracking_map = current_tractor ? (
            map_iframe(latitude_tractor, longitude_tractor, current_tractor)
        ) : (
            <Alert variant="danger">No tracking information can be found.</Alert>
        );

        const tractor_tracking =
            this.props.can_track === 'Y'
                ? tracking_map(reverse_location_tractor, locationTime_tractor, tractor_tracking_map)
                : null;
        //#endregion Tractor Tracking

        //#region Trailer Tracking
        const trailer_tracking_map = current_trailer ? (
            map_iframe(current_trailer.latitude, current_trailer.longitude, current_trailer)
        ) : (
            <Alert variant="danger">No tracking information can be found.</Alert>
        );

        const trailer_tracking =
            this.props.can_track === 'Y' && current_trailer ? (
                tracking_map(current_trailer.formattedLocation, current_trailer.locationTime, trailer_tracking_map)
            ) : (
                <button type="button" onClick={() => console.log(this.props.sp_order)}>
                    info
                </button>
            );
        //#endregion Trailer Tracking

        //#region Delivered Tracking
        let destination_link = null;
        if (this.props.menu2 === 'D') {
            const current_stops = this.props.sp_order
                ? this.props.sp_order.stops
                    ? this.props.sp_order.stops
                    : []
                : [];
            const destination_stop = current_stops.length > 0 ? current_stops[current_stops.length - 1] : null;
            const destination_latitude = destination_stop ? destination_stop.latitude : '';
            const destination_longitude = destination_stop ? destination_stop.longitude : '';
            if (destination_latitude && destination_longitude) {
                destination_link = (
                    <div className="d-flex flex-row justify-content-center">
                        <div className="d-flex flex-column justify-content-center">
                            <h6>{destination_stop.location_name}</h6>
                            <small>{`${destination_stop.city_name}, ${destination_stop.state}`}</small>
                            <iframe
                                title="Delivered Location"
                                width="410"
                                height="300"
                                className="rounded border shadow mt-1"
                                loading="lazy"
                                allowfullscreen
                                referrerpolicy="no-referrer-when-downgrade"
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDqCDa-B3cjWJclHxHUvnImIkC2CN8XZpw&q=${destination_latitude},${destination_longitude}`}
                            />
                        </div>
                    </div>
                );
            }
        }
        //#endregion Delivered Tracking

        //#region Available Tracking
        let not_available_yet = null;
        if (this.props.hours_away > 2 && this.props.menu2 === 'A') {
            not_available_yet = (
                <div className="d-flex flex-row justify-content-center w-100 p-2">
                    <div className="d-flex flex-column justify-content-center w-100 p-2">
                        <Alert variant="warning" className="w-100">
                            <label>Tracking will be available in ({this.props.hours_away}) hours.</label>
                        </Alert>
                    </div>
                </div>
            );
        }
        //#endregion Available Tracking

        return (
            <Card style={{ fontSize: '80%', height: '100%' }}>
                <Container>
                    <Row className="d-flex flex-row justify-content-between px-3 pt-3 ">
                        <Col lg={4} sm={12} className="d-flex flex-row justify-content-start p-0">
                            <h6 className="align-self-center py-1 m-0">Tracking</h6>
                        </Col>
                        {this.props.can_track === 'Y' ? (
                            <Col lg={8} sm={12} className="d-flex flex-row justify-content-end p-0">
                                <Button
                                    size="sm"
                                    style={{ fontSize: '90%' }}
                                    className="me-2"
                                    onClick={() => this.setState({ tracking_type: 'tractor' })}
                                    variant={tracking_type === 'tractor' ? 'warning' : 'outline-secondary'}
                                >
                                    Tractor
                                </Button>
                                <Button
                                    size="sm"
                                    style={{ fontSize: '90%' }}
                                    onClick={() => this.setState({ tracking_type: 'trailer' })}
                                    variant={tracking_type === 'trailer' ? 'warning' : 'outline-secondary'}
                                >
                                    Trailer
                                </Button>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
                <hr className="mt-2 mb-0" />
                {destination_link}
                {not_available_yet}
                {tracking_type === 'trailer' ? trailer_tracking : tractor_tracking}
            </Card>
        );
    }
}
