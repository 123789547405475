import React, { Component } from 'react';
import s_logo from '../Media/S_logo.png';

export class UnauthNavbar extends Component {
    static displayName = UnauthNavbar.name;

    render() {
        return (
            <div className="d-flex flex-row justify-content-between bg-dark">
                <div className="d-flex flex-row justify-content-start p-2">
                    <img src={s_logo} alt="" style={{ width: '2rem', height: '2rem' }} />
                    <h6 className="align-self-end text-white pt-2 ps-3">Customer Portal</h6>
                </div>
            </div>
        );
    }
}
