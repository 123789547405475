import React, { Component } from 'react';

export class Title extends Component {
    static displayName = Title.name;

    render() {
        let current_title = 'All Orders';
        if (this.props.title === 'P') {
            current_title = 'In-Progress Orders';
        } else if (this.props.title === 'D') {
            current_title = 'Delivered Orders';
        } else if (this.props.title === 'A') {
            current_title = 'Available Orders';
        } else {
            current_title = 'All Orders';
        }
        return (
            <div className="m-3">
                <h6>{current_title}</h6>
            </div>
        );
    }
}
