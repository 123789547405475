import React, { Component } from 'react';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';

import { AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { UnauthNavbar } from './UnauthNavbar';

import { Home } from './Home';

export class Layout extends Component {
    static displayName = Layout.name;
    static contextType = MsalContext;

    async handleLogin() {
        try {
            const inst = this.props.instance;
            await inst.loginRedirect(loginRequest);
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div>
                <AuthenticatedTemplate>
                    <Home instance={this.props.instance} />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Container fluid="xs">
                        <Row>
                            <Col>
                                <UnauthNavbar />
                            </Col>
                        </Row>
                        <Row className="d-flex flex-row justify-content-center mt-5 p-2">
                            <Col className="d-flex flex-column justify-content-center p-2">
                                <Alert
                                    variant="secondary"
                                    className="d-flex flex-column justify-content-center p-5 w-50 align-self-center"
                                >
                                    <h5 className="mb-5 text-center">You are not currently logged in.</h5>
                                    <Button
                                        variant="warning"
                                        className="align-self-center me-3"
                                        onClick={() => this.handleLogin()}
                                    >
                                        Login
                                    </Button>
                                </Alert>
                            </Col>
                        </Row>
                    </Container>
                </UnauthenticatedTemplate>
            </div>
        );
    }
}
