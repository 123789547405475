const utcDateRegexp = /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/;
const localDateRegexp = /^(\d{4}).(\d{2}).(\d{2}).(\d{2}).(\d{2})/;

/**
 * @private
 * @param {!string} dateString - A date string to be parsed by `regexp`.
 * @param {!RegExp} regexp     - A RegExp for parsing `dateString`.
 * @returns {string} Date formatted as `YYYY-MM-DD HH:MM`.
 */
const parse = (dateString, regexp) => {
    const matches = dateString.match(regexp);
    if (matches === null) {
        return '';
    }

    const [year, month, day, hour, minute] = matches.slice(1);

    return `${year}-${month}-${day} ${hour}:${minute}`;
};

/**
 * @param {!string} dateString - A UTC datetime without separators.
 * @returns {string} Date formatted as `YYYY-MM-DD HH:MM`.
 */
export const parseUTCDatetime = (dateString) => parse(dateString, utcDateRegexp);

/**
 * @param {!string} dateString - A local datetime without separators.
 * @returns {string} Date formatted as `YYYY-MM-DD HH:MM`.
 */
export const parseToDatetime = (dateString) => parse(dateString, localDateRegexp);
