import { Home } from './components/Home';

const AppRoutes = [
    {
        path: '/portal',
        element: <Home />,
    },
];

export default AppRoutes;
