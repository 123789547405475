import { MsalContext } from '@azure/msal-react';
import React, { Component } from 'react';
import { Alert, Button, ButtonGroup, Table } from 'react-bootstrap';
import { CommentsModal } from './CommentsModal';
export class ReeferReport extends Component {
    static displayName = ReeferReport.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            show_modal: false,
            set_order_id: '',
            sort_field: 'order_id',
            ascend: false,
        };
    }

    renderTextColor(temp, min, max) {
        try {
            if (temp) {
                const set_max = max + 2.5;
                const set_min = min - 2.5;
                if (temp < set_min || temp > set_max) {
                    return 'bg-danger text-bold';
                }
                return '';
            }
            return 'bg-danger text-bold';
        } catch (e) {
            return '';
        }
    }

    sortList(trailer, min, max) {
        try {
            if (trailer) {
                const setTemp = trailer.setTemp ? trailer.setTemp : '';
                const returnTemp = trailer.returnTemp ? trailer.returnTemp : '';
                const set_max = max + 2.5;
                const set_min = min - 2.5;
                if (setTemp) {
                    if (setTemp < set_min || setTemp > set_max) {
                        return 1;
                    }
                    if (returnTemp) {
                        if (returnTemp < set_min || returnTemp > set_max) {
                            return 1;
                        }
                        return 0;
                    }
                    return 0;
                }
            } else {
                return 1;
            }
        } catch (e) {
            return 0;
        }
    }

    checkTempFlag(order, temp) {
        const flag_title = '';
        try {
            if (this.props.trailers && order) {
                const temp_min = order.temperature_min - 3;
                const temp_max = order.temperature_max + 3;
                if (temp < temp_min || temp > temp_max) {
                    return (
                        <div title={flag_title}>
                            <svg
                                className=""
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                fill="#e45b5e"
                                class="bi bi-flag-fill"
                                viewBox="0 0 16 16"
                                role="graphics-symbol img"
                            >
                                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                            </svg>
                        </div>
                    );
                }
                return null;
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    keepFlagsAtTop(order) {
        try {
            const trailers = this.props.trailers ? this.props.trailers : [];
            const current_trailer = trailers.find((s) => s.name === order.trailer_id);
            const temp_min = order.temperature_min - 3;
            const temp_max = order.temperature_max + 3;
            if (order) {
                if (current_trailer) {
                    const setTemp = current_trailer.setTemp;
                    const returnTemp = current_trailer.returnTemp;
                    if (setTemp < temp_min || setTemp > temp_max) {
                        return 0;
                    }
                    if (returnTemp < temp_min || returnTemp > temp_max) {
                        return 0;
                    }
                    return 1;
                }
                return 0;
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    render() {
        const { ascend, sort_field, show_modal, set_order_id } = this.state;
        const comments = this.props.comments ? this.props.comments : [];
        const info_icon = (order_id) => (
            <div
                style={{ transform: 'scale(0.75)' }}
                className={`align-self-center opacity-${
                    comments.filter((s) => s.order_id === order_id).length > 0 ? '100' : '50'
                }`}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="black"
                    class="bi bi-info-circle"
                    viewBox="0 0 16 16"
                    role="graphics-symbol img"
                >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
            </div>
        );

        const warning_icon = (
            <div className="d-flex justify-content-center p-1 pb-2" style={{ transform: 'scale(0.8)' }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#e45b5e"
                    class="bi bi-exclamation-circle"
                    viewBox="0 0 16 16"
                    role="graphics-symbol img"
                >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                </svg>
            </div>
        );

        const trailers = this.props.trailers ? this.props.trailers : [];
        const trailers_without_temps = trailers.filter((s) => !s.setTemp && !s.returnTemp);
        const trailers_with_temps = trailers.filter((s) => s.setTemp || s.returnTemp);
        const all_orders = this.props.orders
            ? this.props.orders.filter((s) => s.status === 'P' && (s.temperature_max || s.temperature_min))
            : [];
        let unassigned_orders = [];
        let in_progress_orders = [];

        for (let i = 0; i < all_orders.length; i++) {
            const no_tracking_trailer = trailers_without_temps.find((s) => s.name === all_orders[i].trailer_id);
            const tracking_trailer = trailers_with_temps.find((s) => s.name === all_orders[i].trailer_id);
            if (no_tracking_trailer) {
                unassigned_orders.push(all_orders[i]);
            } else if (tracking_trailer) {
                in_progress_orders.push(all_orders[i]);
            } else {
                unassigned_orders.push(all_orders[i]);
            }
        }

        if (sort_field === 'tempMode' || sort_field === 'setTemp' || sort_field === 'returnTemp') {
            if (ascend) {
                in_progress_orders = in_progress_orders.sort(
                    (a, b) =>
                        (trailers.find((s) => s.name === a.trailer_id)
                            ? trailers.find((s) => s.name === a.trailer_id)[sort_field]
                            : null) -
                        (trailers.find((s) => s.name === b.trailer_id)
                            ? trailers.find((s) => s.name === b.trailer_id)[sort_field]
                            : null),
                );
                unassigned_orders = unassigned_orders.sort(
                    (a, b) =>
                        (trailers.find((s) => s.name === a.trailer_id)
                            ? trailers.find((s) => s.name === a.trailer_id)[sort_field]
                            : null) -
                        (trailers.find((s) => s.name === b.trailer_id)
                            ? trailers.find((s) => s.name === b.trailer_id)[sort_field]
                            : null),
                );
            } else {
                in_progress_orders = in_progress_orders.sort(
                    (b, a) =>
                        (trailers.find((s) => s.name === a.trailer_id)
                            ? trailers.find((s) => s.name === a.trailer_id)[sort_field]
                            : null) -
                        (trailers.find((s) => s.name === b.trailer_id)
                            ? trailers.find((s) => s.name === b.trailer_id)[sort_field]
                            : null),
                );
                unassigned_orders = unassigned_orders.sort(
                    (b, a) =>
                        (trailers.find((s) => s.name === a.trailer_id)
                            ? trailers.find((s) => s.name === a.trailer_id)[sort_field]
                            : null) -
                        (trailers.find((s) => s.name === b.trailer_id)
                            ? trailers.find((s) => s.name === b.trailer_id)[sort_field]
                            : null),
                );
            }
        } else {
            if (ascend) {
                in_progress_orders = in_progress_orders.sort((a, b) => a[sort_field] - b[sort_field]);
                unassigned_orders = unassigned_orders.sort((a, b) => a[sort_field] - b[sort_field]);
            } else {
                in_progress_orders = in_progress_orders.sort((b, a) => a[sort_field] - b[sort_field]);
                unassigned_orders = unassigned_orders.sort((b, a) => a[sort_field] - b[sort_field]);
            }
        }
        in_progress_orders = in_progress_orders.sort((a, b) => this.keepFlagsAtTop(a) - this.keepFlagsAtTop(b));

        const drivers = this.props.drivers ? this.props.drivers : [];

        const unassigned_table = (
            <div className="d-flex flex-row justify-content-center mx-5 mt-4 bg-white rounded">
                <div className="light-table-border p-0 d-flex overflow-auto flex-column w-100">
                    <div className="d-flex flex-row mt-2">
                        {warning_icon}
                        <h6 className="text-muted align-self-center">Temp Sensor Not Reporting (In-Progress Loads)</h6>
                    </div>
                    <Table size="sm" style={{ fontSize: '0.67rem' }}>
                        <thead className="text-muted">
                            <tr>
                                <th
                                    onClick={() => this.setState({ sort_field: 'order_id', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'order_id', ascend: !ascend })}
                                >
                                    Order
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'temperature_min', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'temperature_min', ascend: !ascend })}
                                >
                                    Order Temp Range
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'commodity', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'commodity', ascend: !ascend })}
                                >
                                    Commodity
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'origin_arrival', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'origin_arrival', ascend: !ascend })}
                                >
                                    Pick Departure
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'tractor_id', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'tractor_id', ascend: !ascend })}
                                >
                                    Tractor
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'trailer_id', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'trailer_id', ascend: !ascend })}
                                >
                                    Trailer
                                </th>
                                <th>Driver</th>
                                <th>Cell Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {unassigned_orders.map((d) => (
                                <tr key={d.order_id} className="alternate-row">
                                    <td style={show_modal && set_order_id === d.order_id ? { width: '75%' } : {}}>
                                        <div
                                            title="Comments"
                                            className="d-flex"
                                            onClick={() =>
                                                this.setState({
                                                    show_modal: !show_modal,
                                                    set_order_id: set_order_id ? '' : d.order_id,
                                                })
                                            }
                                            onKeyDown={() =>
                                                this.setState({
                                                    show_modal: !show_modal,
                                                    set_order_id: set_order_id ? '' : d.order_id,
                                                })
                                            }
                                        >
                                            {info_icon(d.order_id)}
                                            <bdi className="ps-1 align-self-center">{d.order_id}</bdi>
                                        </div>
                                        {show_modal && set_order_id === d.order_id ? (
                                            <CommentsModal
                                                order_id={set_order_id}
                                                user={this.props.user}
                                                comments={this.props.comments}
                                                update_comments={(prop) => this.props.update_comments(prop)}
                                                onHide={() =>
                                                    this.setState({ show_modal: false, set_order_id: '', comments: [] })
                                                }
                                                show={show_modal}
                                            />
                                        ) : null}
                                    </td>
                                    <td>
                                        <div className="d-flex">
                                            <i className="align-self-center pe-1">
                                                {d.temperature_min < 0 ? `(${d.temperature_min})` : d.temperature_min}
                                            </i>
                                            <bdi className="align-self-center pe-1">to</bdi>
                                            <i className="align-self-center">
                                                {d.temperature_max < 0 ? `(${d.temperature_max})` : d.temperature_max}
                                            </i>
                                        </div>
                                    </td>
                                    <td>{d.commodity}</td>
                                    <td>{d.origin_arrival}</td>
                                    <td>{d.tractor_id}</td>
                                    <td>{d.trailer_id}</td>
                                    <td>
                                        {drivers.find((s) => s.order_id === d.order_id)
                                            ? drivers.find((s) => s.order_id === d.order_id).name
                                            : '-'}
                                    </td>
                                    <td>
                                        <a
                                            href={`tel: +1${
                                                drivers.find((s) => s.order_id === d.order_id)
                                                    ? drivers.find((s) => s.order_id === d.order_id).phone
                                                    : ''
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {drivers.find((s) => s.order_id === d.order_id)
                                                ? drivers.find((s) => s.order_id === d.order_id).phone
                                                : '-'}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        );

        const progress_table = (
            <div
                className="d-flex flex-row justify-content-center mx-5 mt-4 bg-white rounded mb-5"
                style={{ maxHeight: '30rem', overflow: 'auto' }}
            >
                <div className="light-table-border p-0 d-flex overflow-auto flex-column w-100">
                    <div className="d-flex flex-row mt-2">
                        <h6 className="text-muted ps-2 align-self-center">In-Progress Reefer Loads</h6>
                    </div>
                    <Table size="sm" style={{ fontSize: '0.67rem' }}>
                        <thead className="text-muted">
                            <tr>
                                <th
                                    onClick={() => this.setState({ sort_field: 'order_id', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'order_id', ascend: !ascend })}
                                >
                                    Order
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'temperature_min', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'temperature_min', ascend: !ascend })}
                                >
                                    Order Temp Range
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'tempMode', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'tempMode', ascend: !ascend })}
                                >
                                    Mode
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'setTemp', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'setTemp', ascend: !ascend })}
                                >
                                    Set Temp
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'returnTemp', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'returnTemp', ascend: !ascend })}
                                >
                                    Return Temp
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'commodity', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'commodity', ascend: !ascend })}
                                >
                                    Commodity
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'origin_arrival', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'origin_arrival', ascend: !ascend })}
                                >
                                    Pick Departure
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'tractor_id', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'tractor_id', ascend: !ascend })}
                                >
                                    Tractor
                                </th>
                                <th
                                    onClick={() => this.setState({ sort_field: 'trailer_id', ascend: !ascend })}
                                    onKeyDown={() => this.setState({ sort_field: 'trailer_id', ascend: !ascend })}
                                >
                                    Trailer
                                </th>
                                <th>Driver</th>
                                <th>Cell Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {in_progress_orders.map((d, index) => (
                                <tr className="alternate-row" key={d.order_id}>
                                    <td style={show_modal && set_order_id === d.order_id ? { width: '75%' } : {}}>
                                        <div
                                            title="Comments"
                                            className="d-flex"
                                            onClick={() =>
                                                this.setState({
                                                    show_modal: !show_modal,
                                                    set_order_id: set_order_id ? '' : d.order_id,
                                                })
                                            }
                                            onKeyDown={() =>
                                                this.setState({
                                                    show_modal: !show_modal,
                                                    set_order_id: set_order_id ? '' : d.order_id,
                                                })
                                            }
                                        >
                                            {info_icon(d.order_id)}
                                            <bdi className="ps-1 align-self-center">{d.order_id}</bdi>
                                        </div>
                                        {show_modal && set_order_id === d.order_id ? (
                                            <CommentsModal
                                                order_id={set_order_id}
                                                user={this.props.user}
                                                comments={this.props.comments}
                                                update_comments={(prop) => this.props.update_comments(prop)}
                                                onHide={() =>
                                                    this.setState({ show_modal: false, set_order_id: '', comments: [] })
                                                }
                                                show={show_modal}
                                            />
                                        ) : null}
                                    </td>
                                    <td>
                                        <div className="d-flex">
                                            <i className="align-self-center pe-1">
                                                {d.temperature_min < 0 ? `(${d.temperature_min})` : d.temperature_min}
                                            </i>
                                            <bdi className="align-self-center pe-1">to</bdi>
                                            <i className="align-self-center">
                                                {d.temperature_max < 0 ? `(${d.temperature_max})` : d.temperature_max}
                                            </i>
                                        </div>
                                    </td>
                                    <td>
                                        {trailers.find((s) => s.name === d.trailer_id)
                                            ? trailers.find((s) => s.name === d.trailer_id).tempMode
                                            : '-'}
                                    </td>
                                    <td>
                                        <div className="d-flex">
                                            <bdi className="pe-1">
                                                {trailers.find((s) => s.name === d.trailer_id)
                                                    ? trailers.find((s) => s.name === d.trailer_id).setTemp
                                                        ? trailers.find((s) => s.name === d.trailer_id).setTemp
                                                        : '-'
                                                    : '-'}
                                            </bdi>

                                            {trailers.find((s) => s.name === d.trailer_id)
                                                ? this.checkTempFlag(
                                                      d,
                                                      trailers.find((s) => s.name === d.trailer_id).setTemp,
                                                  )
                                                : this.checkTempFlag(d, '')}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex">
                                            <bdi className="pe-1">
                                                {trailers.find((s) => s.name === d.trailer_id)
                                                    ? trailers.find((s) => s.name === d.trailer_id).returnTemp
                                                        ? trailers.find((s) => s.name === d.trailer_id).returnTemp
                                                        : '-'
                                                    : '-'}
                                            </bdi>
                                            {trailers.find((s) => s.name === d.trailer_id)
                                                ? this.checkTempFlag(
                                                      d,
                                                      trailers.find((s) => s.name === d.trailer_id).returnTemp,
                                                  )
                                                : this.checkTempFlag(d, '')}
                                        </div>
                                    </td>
                                    <td>{d.commodity}</td>
                                    <td>{d.origin_arrival}</td>
                                    <td>{d.tractor_id}</td>
                                    <td>{d.trailer_id}</td>
                                    <td>
                                        {drivers.find((s) => s.order_id === d.order_id)
                                            ? drivers.find((s) => s.order_id === d.order_id).name
                                            : '-'}
                                    </td>
                                    <td>
                                        <a
                                            href={`tel: +1${
                                                drivers.find((s) => s.order_id === d.order_id)
                                                    ? drivers.find((s) => s.order_id === d.order_id).phone
                                                    : ''
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {drivers.find((s) => s.order_id === d.order_id)
                                                ? drivers.find((s) => s.order_id === d.order_id).phone
                                                : '-'}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        );

        const loading = (
            <Alert className="mt-2" variant="secondary">
                Loading...
            </Alert>
        );

        const tables = (
            <div className="d-flex flex-column">
                {unassigned_orders.length > 0 ? unassigned_table : null}
                {progress_table}
            </div>
        );

        return <div className="d-flex flex-column">{this.props.drivers_loaded ? tables : loading}</div>;
    }

    //#region Component Did Mount

    //#endregion Component Did Mount
}
