import { MsalContext } from '@azure/msal-react';
import React, { Component } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import s_logo from '../Media/S_logo.png';
import { loginRequest } from '../authConfig';

export class Navbar extends Component {
    static displayName = Navbar.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            show_orders_menu: false,
            show_invoices_menu: false,
        };
        this.setMenu2 = this.setMenu2.bind(this);
    }

    async handleLogout() {
        try {
            const inst = this.props.instance;
            const response = await inst.logoutRedirect(loginRequest);
            const data = response.json();
        } catch (e) {
            console.log(e);
        }
    }

    setMenu2(menu1, menu2) {
        this.props.onMenu1(menu1);
        this.props.onMenu2(menu2);
        if (menu1 === 'Orders') {
            this.props.getOrders();
        }
        this.setState({ show_orders_menu: false });
    }

    render() {
        const { show_orders_menu, show_invoices_menu } = this.state;
        const display_name = this.props.instance ? this.props.instance.getAllAccounts()[0].name : 'Unknown User';

        const orders_dropdown = (
            <Dropdown
                autoClose="inside"
                id="orders-dropdown"
                size="sm"
                className="align-self-center ps-3"
                onClick={() => this.setState({ show_invoices_menu: false, show_orders_menu: !show_orders_menu })}
                show={show_orders_menu}
            >
                <Dropdown.Toggle variant="dark" id="overdue-items-toggle-btn" style={{ fontSize: '98%' }}>
                    Orders
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark" className="overdue-dropdown-container p-2" style={{ fontSize: '98%' }}>
                    <Dropdown.Item className="text-white" onClick={() => this.setMenu2('Orders', '')}>
                        All Orders
                    </Dropdown.Item>
                    <Dropdown.Item className="text-white" onClick={() => this.setMenu2('Orders', 'Available Orders')}>
                        Available
                    </Dropdown.Item>
                    <Dropdown.Item className="text-white" onClick={() => this.setMenu2('Orders', 'In-Progress Orders')}>
                        In-Progress
                    </Dropdown.Item>
                    <Dropdown.Item className="text-white" onClick={() => this.setMenu2('Orders', 'Delivered Orders')}>
                        Delivered
                    </Dropdown.Item>
                </Dropdown.Menu>{' '}
                :
            </Dropdown>
        );

        const invoices_dropdown = (
            <Dropdown
                autoClose="inside"
                id="invoices-dropdown"
                size="sm"
                className="align-self-center ps-3"
                show={show_invoices_menu}
                onClick={() => this.setState({ show_invoices_menu: !show_invoices_menu, show_orders_menu: false })}
            >
                <Dropdown.Toggle variant="dark" id="overdue-items-toggle-btn" style={{ fontSize: '98%' }}>
                    Invoices
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark" className="overdue-dropdown-container p-2" style={{ fontSize: '98%' }}>
                    <Dropdown.Item className="text-white" onClick={() => this.setMenu2('Invoices', '')}>
                        All Invoices
                    </Dropdown.Item>
                    <Dropdown.Item className="text-white" onClick={() => this.setMenu2('Invoices', 'Option #1')}>
                        Option #1
                    </Dropdown.Item>
                    <Dropdown.Item className="text-white" onClick={() => this.setMenu2('Invoices', 'Option #2')}>
                        Option #2
                    </Dropdown.Item>
                    <Dropdown.Item className="text-white" onClick={() => this.setMenu2('Invoices', 'Option #3')}>
                        Option #3
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );

        return (
            <div className="d-flex flex-row justify-content-between bg-dark" style={{ fontSize: '80%' }}>
                <div className="d-flex flex-row justify-content-start p-2">
                    <img src={s_logo} alt="" style={{ width: '2rem', height: '2rem' }} />
                    <h6 className="align-self-end text-white pt-2 ps-3">Customer Portal</h6>
                    <div className="d-flex flex-row ps-3">
                        <Button
                            variant="dark"
                            style={{ fontSize: '0.85rem' }}
                            className={this.props.screen_view === 'Orders' ? 'underlined opacity-75' : ''}
                            onClick={() => this.props.onChangeView('Orders')}
                        >
                            Orders
                        </Button>
                    </div>
                    <div className="d-flex flex-row ps-3">
                        <Button
                            variant="dark"
                            style={{ fontSize: '0.85rem' }}
                            className={this.props.screen_view === 'Reports' ? 'underlined opacity-75' : ''}
                            onClick={() => this.props.onChangeView('Reports')}
                        >
                            Reports
                        </Button>
                    </div>
                    <div className="d-flex flex-row ps-3">
                        <Button
                            variant="dark"
                            style={{ fontSize: '0.85rem' }}
                            className={this.props.screen_view === 'Invoices' ? 'underlined opacity-75' : ''}
                            onClick={() => this.props.onChangeView('Invoices')}
                        >
                            Accounting
                        </Button>
                    </div>
                    <div className="d-flex flex-row ps-3">
                        <Button
                            variant="dark"
                            style={{ fontSize: '0.85rem' }}
                            className={this.props.screen_view === 'Reefer Report' ? 'underlined opacity-75' : ''}
                            onClick={() => this.props.onChangeView('Reefer Report')}
                        >
                            Reefer Report
                        </Button>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center">
                    <label className="text-white align-self-center pe-4">
                        <bdi className="text-white align-self-center pe-3"> {display_name}</bdi>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-person-circle"
                            viewBox="0 0 16 16"
                            role="graphics-symbol img"
                        >
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                            <path
                                fill-rule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                            />
                        </svg>
                    </label>

                    <Button
                        variant="warning"
                        className="align-self-center me-3"
                        size="sm"
                        style={{ fontSize: '98%' }}
                        onClick={() => this.handleLogout()}
                    >
                        Log out
                    </Button>
                </div>
            </div>
        );
    }
}
