import { MsalContext } from '@azure/msal-react';
import { differenceInHours } from 'date-fns';
import React, { Component } from 'react';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';
import * as fetchFunction from './Functions/FetchFunctions';
import { Navbar } from './Navbar';
import { OrderDetails } from './OrderDetails';
import { OrdersTable } from './OrdersTable';
import { ReeferReport } from './ReeferReport';
import { RouteDetails } from './RouteDetails';
import { Title } from './Title';
import { Tracking } from './Tracking';

export class Home extends Component {
    static displayName = Home.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            menu1: '',
            menu2: 'P',
            orders: [],
            orders_loaded: false,
            orders_failed: false,
            quick_filter: 'D',
            stops: [],
            stops_loaded: false,
            selected_order: '',
            sp_order: null,
            filter: { field: '', value: '' },
            sort_field: '',
            sort_direction: 'H',
            current_shipper: '',
            sql_order: null,
            initialized: false,
            trailers: [],
            trailers_loaded: false,
            tractors: [],
            tractors_loaded: false,
            can_track: 'N',
            hours_away: 0,
            screen_view: 'Orders',
            drivers: [],
            drivers_loaded: false,
            comments: [],
            comments_loaded: false,
        };
    }

    componentDidMount() {
        const inst = this.props.instance;
        this.getData();
        //this.timer = setInterval(() => this.getData(), 10000);
    }

    componentWillUnmount() {
        //  this.timer = null;
    }

    async getData() {
        await this.GetOrders();
        await this.GetTrailers();
        await this.GetTractors();
        await this.GetDriversContact();
        await this.get_comments();
    }

    get_user() {
        const account = this.context.accounts;

        if (account && account.length > 0) {
            return account[0];
        }

        return null;
    }

    render() {
        const {
            menu1,
            menu2,
            orders,
            orders_loaded,
            orders_failed,
            quick_filter,
            stops,
            stops_loaded,
            selected_order,
            sp_order,
            filter,
            sort_field,
            sort_direction,
            current_shipper,
            sql_order,
            trailers,
            trailers_loaded,
            tractors,
            tractors_loaded,
            can_track,
            hours_away,
            screen_view,
            drivers,
            drivers_loaded,
            comments,
            comments_loaded,
        } = this.state;

        const divider_chevron = (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                class="bi bi-chevron-right"
                viewBox="0 0 14 14"
                className="align-self-center opacity-50"
                role="graphics-symbol img"
            >
                <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
            </svg>
        );

        let current_orders = orders ? orders : [];
        if (filter.field && filter.value) {
            current_orders = orders.filter((s) => s[filter.field].contains(filter.value));
        } else {
            current_orders = orders ? orders : [];
        }

        if (menu2) {
            current_orders = orders.filter((s) => s.status === menu2);
        } else {
            current_orders = orders;
        }

        let menu2_title = 'All Orders';
        if (menu2 === 'P') {
            menu2_title = 'In-Progress Orders';
        } else if (menu2 === 'D') {
            menu2_title = 'Delivered Orders';
        } else if (menu2 === 'A') {
            menu2_title = 'Available Orders';
        } else if (menu2 === 'V') {
            menu2_title = 'Voided Orders';
        } else {
            menu2_title = 'All Orders';
        }

        const data_selection_row = (
            <Row className="d-flex flex-row justify-content-between bg-white shadow-1 py-2 px-4">
                <Col className="d-flex flex-row justify-content-start">
                    <Button
                        variant="outline-secondary"
                        className="opacity-75 border-0 align-self-center"
                        onClick={() => {
                            window.location.pathname = '/';
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-house-door-fill"
                            viewBox="0 0 20 20"
                            role="graphics-symbol img"
                        >
                            <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                        </svg>
                    </Button>
                    {screen_view === 'Orders' ? divider_chevron : null}
                    {screen_view ? (
                        <Button
                            variant="outline-secondary"
                            className="opacity-75 border-0 align-self-center"
                            style={{ fontSize: '90%' }}
                            onClick={() => this.setState({ menu2: '' })}
                        >
                            {screen_view}
                        </Button>
                    ) : null}
                    {menu2 ? divider_chevron : null}
                    {menu2 ? (
                        <label
                            variant="outline-secondary"
                            className="opacity-50 align-self-center p-3"
                            style={{ fontSize: '90%' }}
                        >
                            {menu2_title}
                        </label>
                    ) : null}
                </Col>
                <Col className="d-flex flex-row justify-content-end p-0" style={{ height: '3rem' }} />
            </Row>
        );

        const filter_row = orders_loaded ? (
            <Row className="d-flex flex-row justify-content-center pb-3">
                <Col className="d-flex flex-column justify-content-center">
                    <div className="d-flex flex-row justify-content-between">
                        <Button
                            variant={menu2 === 'A' ? 'secondary' : 'outline-secondary'}
                            style={{ width: '23%', fontSize: '69%' }}
                            size="sm"
                            onClick={() => this.setState({ menu2: 'A', selected_order: null })}
                        >
                            Available
                        </Button>
                        <Button
                            variant={menu2 === 'P' ? 'secondary' : 'outline-secondary'}
                            style={{ width: '23%', fontSize: '69%' }}
                            size="sm"
                            onClick={() => this.setState({ menu2: 'P', selected_order: null })}
                        >
                            In-Progress
                        </Button>
                        <Button
                            variant={menu2 === 'D' ? 'secondary' : 'outline-secondary'}
                            style={{ width: '23%', fontSize: '69%', selected_order: null }}
                            size="sm"
                            onClick={() => this.setState({ menu2: 'D', selected_order: null })}
                        >
                            Delivered
                        </Button>
                        <Button
                            variant={menu2 === '' ? 'secondary' : 'outline-secondary'}
                            style={{ width: '23%', fontSize: '69%' }}
                            size="sm"
                            onClick={() => this.setState({ menu2: '', selected_order: null })}
                        >
                            All
                        </Button>
                    </div>
                </Col>
            </Row>
        ) : null;

        const title_row = orders_loaded ? (
            <Row className="d-flex flex-row justify-content-center">
                <Col className="p-0 m-0">
                    <Title title={menu2 ? menu2 : menu1} description={'Sample description for '} />
                </Col>
            </Row>
        ) : (
            <div className="d-flex flex-column justify-content-center py-3">
                <Alert className="text-center">Loading...</Alert>
            </div>
        );

        const orders_table =
            orders_loaded && trailers_loaded ? (
                <OrdersTable
                    orders={current_orders}
                    menu2={menu2}
                    trailers={trailers}
                    export_to_csv={() => console.log('exporting...')}
                    set_field={(prop) => this.setState({ sort_field: prop })}
                    sort_field={sort_field}
                    sort_direction={sort_direction}
                    set_direction={() => this.setState({ sort_direction: sort_direction === 'H' ? 'L' : 'H' })}
                    selected_order={selected_order}
                    update_selected_order={(prop) => this.GetOrderById(prop)}
                    update_filter={(field, value) => this.setState({ filter: { field: field, value: value } })}
                />
            ) : (
                <div className="p-3">
                    <Alert className="text-center">Loading...</Alert>
                </div>
            );

        const navbar_row = (
            <Row>
                <Col>
                    <Navbar
                        instance={this.props.instance}
                        onMenu1={(prop) => this.setState({ menu1: prop })}
                        onMenu2={(prop) => this.setState({ menu2: prop })}
                        getOrders={(prop) => this.GetOrders(prop)}
                        onChangeView={(prop) =>
                            this.setState({ screen_view: prop, menu2: prop !== 'Orders' ? '' : 'P' })
                        }
                        screen_view={screen_view}
                    />
                </Col>
            </Row>
        );

        //#region Orders Row
        const orders_row = (
            <Row className="m-3 d-flex flex-row justify-content-center">
                <Col
                    xl={6}
                    lg={6}
                    med={12}
                    className="bg-white d-flex flex-column justify-content-start rounded border"
                >
                    <Container fluid="xs">
                        {title_row}
                        {filter_row}
                    </Container>

                    {menu1 === 'Orders' ? (
                        <Row style={{ overflow: 'auto', height: '40rem' }}>
                            <Col>{orders_table}</Col>
                        </Row>
                    ) : null}
                </Col>
                <Col xl={6} lg={6} med={12} className="d-flex flex-column justify-content-between">
                    {selected_order ? (
                        <Row
                            style={{ overflow: 'auto', height: '54%' }}
                            className="d-flex flex-row justify-content-between"
                        >
                            <Col className="m-0 px-3">
                                <OrderDetails
                                    order={sp_order}
                                    trailers={trailers}
                                    trailers_loaded={trailers_loaded}
                                    menu2={menu2}
                                    can_track={can_track}
                                />
                            </Col>
                            <Col className="m-0 px-0">
                                <Tracking
                                    sp_order={sp_order}
                                    sql_order={sql_order}
                                    trailers={trailers}
                                    trailers_loaded={trailers_loaded}
                                    tractors={tractors}
                                    tractors_loaded={tractors_loaded}
                                    menu2={menu2}
                                    can_track={can_track}
                                    hours_away={hours_away}
                                />
                            </Col>
                        </Row>
                    ) : null}
                    {selected_order ? (
                        <Row
                            className="d-flex flex-row justify-content-end"
                            style={{ overflow: 'auto', height: '44%' }}
                        >
                            <Col className="d-flex flex-column justify-content-end m-0 pe-0 ps-3">
                                <RouteDetails stops={stops} stops_loaded={stops_loaded} />
                            </Col>
                        </Row>
                    ) : null}
                </Col>
            </Row>
        );
        //#endregion Orders Row

        //#region Reports Row
        const reports_bi_report = (
            <iframe
                title="Loblaws Analysis"
                width="1140"
                height="600"
                className="align-self-center"
                src="https://app.powerbi.com/reportEmbed?reportId=ad7715b8-a9ca-400e-8d39-ede32810e796&autoAuth=true&ctid=6e656c29-cc78-41f6-a482-7968f203ef0d&filterPaneEnabled=false"
                frameborder="1"
                allowFullScreen={true}
            />
        );

        const reports_row = (
            <Row className="m-3 d-flex flex-row justify-content-center">
                <Col className="bg-white d-flex flex-column justify-content-start rounded border">
                    {reports_bi_report}
                </Col>
            </Row>
        );
        //#endregion Reports Row

        //#region Reefer Report Row

        const reefer_orders = orders ? orders.filter((s) => s.continuous === 'Y' && s.status === 'P') : [];
        const reefer_trailers = trailers ? trailers : [];
        const unassigned_orders = reefer_orders
            ? reefer_orders.filter((s) => trailers.filter((d) => d.name === s.trailer_id).length === 0)
            : [];
        const reefer_report = (
            <ReeferReport
                trailers={reefer_trailers}
                drivers={drivers}
                drivers_loaded={drivers_loaded}
                unassigned_orders={unassigned_orders}
                orders={reefer_orders}
                comments={comments}
                comments_loaded={comments_loaded}
                user={this.get_user()}
                update_comments={(prop) => this.setState({ comments: JSON.parse(prop) })}
            />
        );
        //#endregion Reefer Report Row

        return (
            <Container fluid="xs">
                {navbar_row}
                {data_selection_row}
                {screen_view === 'Orders' ? orders_row : null}
                {screen_view === 'Reports' ? reports_row : null}
                {screen_view === 'Reefer Report' ? reefer_report : null}
            </Container>
        );
    }

    async validateUser() {
        const account = this.context.accounts;
        try {
            if (account.length > 0) {
                const current_account = { isAuthenticated: false, tenantId: account[0].tenantId, roles: [] };
                const response = await fetch('api/Login', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(current_account),
                });
                const data = await response.json();
                if (data.status === 401) {
                    return 'Unauthorized';
                }
                return data.token;
            }
            return 'Unauthorized';
        } catch (e) {
            return 'Unauthorized';
        }
    }

    async GetDriversContact() {
        try {
            const auth = await this.validateUser();
            const response = await fetch('Order/GetDriversContact', {
                method: 'GET',
                headers: { Authorization: `Bearer ${auth}` },
            });
            const data = await response.json();
            this.setState({ drivers: data, drivers_loaded: true });
        } catch (e) {
            console.log(e);
            this.setState({ drivers: [], drivers_loaded: true });
        }
    }

    async GetOrders() {
        try {
            await this.validateUser().then((auth) =>
                fetch('Order/GetLoblawsView', { method: 'GET', headers: { Authorization: `Bearer ${auth}` } })
                    .then((res) => {
                        return res.json();
                    })
                    .then((data) => {
                        let new_list = [];
                        for (let i = 0; i < data.length; i++) {
                            const current_order = data[i];
                            if (new_list.filter((s) => s.order_id === data[i].order_id).length === 0) {
                                if (current_order.on_time_status === 'DELAYED') {
                                    current_order.on_time = 'danger';
                                } else {
                                    current_order.on_time = '';
                                }
                                new_list.push(current_order);
                            } else {
                                const existing_order = new_list.find((s) => s.order_id === current_order.order_id);
                                if (existing_order) {
                                    existing_order.on_time_status =
                                        existing_order.on_time_status === 'DELAYED' ||
                                        current_order.on_time_status === 'DELAYED'
                                            ? 'DELAYED'
                                            : '';
                                    existing_order.on_time =
                                        existing_order.on_time_status === 'DELAYED' ? 'danger' : '';
                                    new_list = new_list.filter((s) => s.order_id !== existing_order.order_id);
                                    new_list.push(existing_order);
                                }
                            }
                        }
                        this.setState({
                            orders: new_list,
                            orders_loaded: true,
                            orders_failed: false,
                            menu1: 'Orders',
                        });
                    }),
            );
        } catch (e) {
            this.setState({ orders: [], orders_loaded: true, orders_failed: true, menu1: '' });
        }
    }

    async GetOrderById(order) {
        try {
            this.setState({ hours_away: 0, stops_loaded: false, stops: [] });
            const pu_date = order.origin_arrival ? new Date(fetchFunction.parseToDatetime(order.origin_arrival)) : '';
            let can_track = 'N';
            let hours_away = 0;
            if (pu_date && this.state.menu2 === 'A') {
                hours_away = differenceInHours(pu_date, new Date());
                if (hours_away <= 2) {
                    can_track = 'Y';
                }
            } else if (this.state.menu2 === 'P') {
                can_track = 'Y';
            } else {
                can_track = 'N';
            }
            const current_order = order.order_id.toString().replaceAll('0', '%30');
            await this.validateUser().then((auth) => {
                fetch(`Order/GetOrderById/${current_order}/${order.company_id}`, {
                    method: 'GET',
                    headers: { Authorization: `Bearer ${auth}` },
                })
                    .then((res) => {
                        return res.json();
                    })
                    .then((data) => {
                        const json = JSON.parse(data.Value);
                        const order_json = json;

                        if (order_json) {
                            order_json.tractor_id = order.tractor_id;
                            order_json.trailer_id = order.trailer_id;
                            const stops = [];
                            for (let i = 0; i < order_json.stops.length; i++) {
                                const current_stop = order_json.stops[i];
                                if (stops.filter((s) => s.on_time_status === 'DELAYED').length > 0) {
                                    current_stop.on_time = 'danger';
                                    current_stop.on_time_status = 'DELAYED';
                                } else {
                                    if (order_json.stops[i].sched_arrive_early) {
                                        const test_date = new Date(
                                            fetchFunction.parseUTCDatetime(order_json.stops[i].sched_arrive_early),
                                        );

                                        if (
                                            test_date < new Date() &&
                                            order_json.stops[i].status !== 'D' &&
                                            !order_json.stops[i].actual_arrival
                                        ) {
                                            current_stop.on_time = 'danger';
                                            current_stop.on_time_status = 'DELAYED';
                                        } else if (test_date > new Date() && order_json.stops[i].status !== 'D') {
                                            current_stop.on_time = 'secondary';
                                            current_stop.on_time_status = 'ON-TIME';
                                        } else {
                                            current_stop.on_time = 'success';
                                            current_stop.on_time_status = 'COMPLETED';
                                        }
                                    }
                                }

                                stops.push(current_stop);
                            }
                            console.log(stops);
                            this.setState({
                                selected_order: order_json.id,
                                sp_order: order_json,
                                stops: stops,
                                stops_loaded: true,
                                sql_order: order,
                                can_track: can_track,
                                hours_away: hours_away,
                            });
                        }
                    });
            });
        } catch (e) {
            this.setState({ selected_order: '', sp_order: null, stops: [], sql_order: null });
        }
    }

    async GetTrailers() {
        try {
            const auth = await this.validateUser();
            const options = {
                method: 'GET',
                headers: { Authorization: `Bearer ${auth}` },
            };
            const samsara_response = await fetch('Samsara/GetTrailers', options);
            const samsara_data = await samsara_response.json();
            const final_samsara_data = [];
            for (let i = 0; i < samsara_data.length; i++) {
                const current_trailer = samsara_data[i];
                current_trailer.name = current_trailer.name.replaceAll('SLC', '');
                final_samsara_data.push(current_trailer);
            }
            const ibright_response = await fetch('Order/GetIBrightTrailers', options);
            const ibright_data = await ibright_response.json();
            const esolutions_response = await fetch('Esolutions', options);
            const esolutions_data = await esolutions_response.json();
            const all_trailers = esolutions_data.Value.concat(final_samsara_data);
            this.setState({ trailers: all_trailers.concat(ibright_data), trailers_loaded: true });
        } catch (e) {
            console.log(e);
        }
    }

    async GetTractors() {
        try {
            const auth = await this.validateUser();
            const response = await fetch('Samsara/GetTractors', {
                method: 'GET',
                headers: { Authorization: `Bearer ${auth}` },
            });
            const data = await response.json();
            let tractors = JSON.parse(data.Value).data;
            let pagination = JSON.parse(data.Value).pagination;
            while (pagination.hasNextPage) {
                const next_data = await this.checkNextPage(
                    pagination.endCursor,
                    pagination.hasNextPage,
                    auth,
                    'Samsara/GetTractors',
                );
                tractors = tractors.concat(next_data.data);
                pagination = next_data.pagination;
            }
            this.setState({ tractors: tractors, tractors_loaded: true });
        } catch (e) {
            console.log(e);
        }
    }

    async GetNextTractors(cursor, auth) {
        try {
            const response = await fetch(`Samsara/GetTractorsCursor/${cursor}`, {
                method: 'GET',
                headers: { Authorization: `Bearer ${auth}` },
            });
            const data = await response.json();
            const tractors = JSON.parse(data.Value);
            return tractors;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    async checkNextPage(cursor, hasNextPage, auth, endpoint) {
        if (hasNextPage) {
            try {
                const response = await fetch(`${endpoint}Cursor/${cursor}`, {
                    method: 'GET',
                    headers: { Authorization: `Bearer ${auth}` },
                });
                const data = await response.json();
                const return_data = JSON.parse(data.Value);
                return return_data;
            } catch (e) {
                console.log(e);
                return [];
            }
        } else {
            return [];
        }
    }

    async get_comments() {
        try {
            await fetch('Comments')
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    console.log(data);
                    this.setState({ comments: data, comments_loaded: true });
                });
        } catch (e) {
            console.log(e);
        }
    }
}
