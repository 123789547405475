import { format } from 'date-fns';
import * as FileSaver from 'file-saver';
import React, { Component } from 'react';
import { Button, Card, Form, Table } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import tractor_icon from '../Media/tractor_icon_2.png';
import trailer_icon from '../Media/trailer_icon.png';
import * as fetchFunction from './Functions/FetchFunctions';

export class OrdersTable extends Component {
    static displayName = OrdersTable.name;
    constructor(props) {
        super(props);
        this.state = {
            search_input: '',
            show_temp_flag: '',
        };
        this.exportToCSV = this.exportToCSV.bind(this);
    }

    setSort(field) {
        this.props.set_field(field);
        this.props.set_direction();
    }

    checkTempFlag(order) {
        if (this.props.menu2 === 'P') {
            let flag_title = '';
            try {
                if (this.props.trailers && order) {
                    const current_trailer = this.props.trailers.find((s) => s.name === order.trailer_id);
                    const temp_min = order.temperature_min ? order.temperature_min - 3 : '-';
                    const temp_max = order.temperature_max ? order.temperature_max + 3 : '-';

                    const trailer_set_temp = current_trailer ? current_trailer.setTemp : null;
                    const trailer_return_temp = current_trailer ? current_trailer.returnTemp : null;
                    const trailer_mode = current_trailer
                        ? current_trailer.tempMode
                            ? current_trailer.tempMode.toUpperCase()
                            : ''
                        : '';
                    if (temp_min !== '-' && temp_max !== '-') {
                        if (trailer_mode.includes('DEFROST')) {
                            flag_title = '';
                        } else {
                            if (!trailer_set_temp || !trailer_return_temp) {
                                flag_title = 'No trailer set temp or return temp found';
                            } else {
                                if (trailer_set_temp < temp_min || trailer_set_temp > temp_max) {
                                    flag_title = 'Trailer set temp outside order temp threshold';
                                } else if (trailer_return_temp < temp_min || trailer_return_temp > temp_max) {
                                    flag_title = 'Trailer return temp outside order temp threshold';
                                } else {
                                    flag_title = '';
                                }
                            }
                        }
                    } else {
                        if (trailer_set_temp || trailer_return_temp) {
                            flag_title = 'Order temp not set, but trailer is reporting set/return temps';
                        } else {
                            flag_title = '';
                        }
                    }
                    if (flag_title) {
                        return (
                            <div title={flag_title}>
                                <svg
                                    className=""
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="13"
                                    fill="#e45b5e"
                                    class="bi bi-flag-fill"
                                    viewBox="0 0 16 16"
                                    role="graphics-symbol img"
                                >
                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                </svg>
                            </div>
                        );
                    }
                    return null;
                }
            } catch (e) {
                console.log(e);
                return null;
            }
        } else {
            return null;
        }
    }

    checkTrailer(order) {
        if (this.props.menu2 === 'P' && order && order.trailer_id === '-') {
            return (
                <img
                    src={trailer_icon}
                    alt="trailer_icon"
                    style={{ width: '1.75rem', height: '0.6rem' }}
                    className="align-self-center opacity-75"
                    title="No trailer assigned"
                />
            );
        }

        return null;
    }
    getTrailerOwner(order) {
        if (this.props.menu2 === 'P') {
            try {
                if (this.props.trailers && order) {
                    const current_trailer = this.props.trailers.find((s) => s.name === order.trailer_id);
                    if (current_trailer) {
                        return current_trailer.owner;
                    }
                    return 'Unknown';
                }
            } catch (e) {
                console.log(e);
                return null;
            }
        } else {
            return null;
        }
    }

    checkTractor(order) {
        if (this.props.menu2 === 'P' && order && order.tractor_id === '-') {
            return (
                <img
                    src={tractor_icon}
                    alt="tractor_icon"
                    style={{ width: '1.45rem', height: '0.65rem' }}
                    className="align-self-center opacity-75 ms-1"
                    title="No tractor assigned"
                />
            );
        }

        return null;
    }

    render() {
        const { search_input } = this.state;
        let current_orders = this.props.orders ? this.props.orders : [];

        const divider_chevron = (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                fill="currentColor"
                class="bi bi-chevron-right"
                viewBox="4 2 12 14"
                style={this.props.sort_direction === 'H' ? { rotate: '90deg' } : { rotate: '270deg' }}
                role="graphics-symbol img"
            >
                <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
            </svg>
        );

        //#region Sort Orders
        if (this.props.sort_field === 'id') {
            if (this.props.sort_direction === 'H') {
                current_orders = current_orders.sort((a, b) => a.order_id.localeCompare(b.order_id));
            } else {
                current_orders = current_orders.sort((b, a) => a.order_id.localeCompare(b.order_id));
            }
        } else if (this.props.sort_field === 'bol') {
            if (this.props.sort_direction === 'H') {
                current_orders = current_orders.sort((a, b) =>
                    (a.blnum !== '-') & (b.blnum !== '-') ? a.blnum.localeCompare(b.blnum) : false,
                );
            } else {
                current_orders = current_orders.sort((b, a) =>
                    (a.blnum !== '-') & (b.blnum !== '-') ? a.blnum.localeCompare(b.blnum) : false,
                );
            }
        } else if (this.props.sort_field === 'origin') {
            if (this.props.sort_direction === 'H') {
                current_orders = current_orders.sort((a, b) => a.shipper_city.localeCompare(b.shipper_city));
            } else {
                current_orders = current_orders.sort((b, a) => a.shipper_city.localeCompare(b.shipper_city));
            }
        } else if (this.props.sort_field === 'destination') {
            if (this.props.sort_direction === 'H') {
                current_orders = current_orders.sort((a, b) => a.dest_city.localeCompare(b.dest_city));
            } else {
                current_orders = current_orders.sort((b, a) => a.dest_city.localeCompare(b.dest_city));
            }
        } else if (this.props.sort_field === 'status') {
            if (this.props.sort_direction === 'H') {
                current_orders = current_orders.sort((a, b) => a.on_time - b.on_time);
            } else {
                current_orders = current_orders.sort((b, a) => a.on_time - b.on_time);
            }
        } else if (this.props.sort_field === 'departed') {
            if (this.props.sort_direction === 'H') {
                current_orders = current_orders.sort(
                    (a, b) =>
                        new Date(fetchFunction.parseToDatetime(a.origin_arrival)) -
                        new Date(fetchFunction.parseToDatetime(b.origin_arrival)),
                );
            } else {
                current_orders = current_orders.sort(
                    (b, a) =>
                        new Date(fetchFunction.parseToDatetime(a.origin_arrival)) -
                        new Date(fetchFunction.parseToDatetime(b.origin_arrival)),
                );
            }
        } else if (this.props.sort_field === 'arrive') {
            if (this.props.sort_direction === 'H') {
                current_orders = current_orders.sort(
                    (a, b) =>
                        new Date(fetchFunction.parseToDatetime(a.destination_arrival)) -
                        new Date(fetchFunction.parseToDatetime(b.destination_arrival)),
                );
            } else {
                current_orders = current_orders.sort(
                    (b, a) =>
                        new Date(fetchFunction.parseToDatetime(a.destination_arrival)) -
                        new Date(fetchFunction.parseToDatetime(b.destination_arrival)),
                );
            }
        }
        //#endregion Sort Orders

        //#region Filter
        if (search_input) {
            const search_input_lowercase = search_input.toLowerCase();
            current_orders = current_orders.filter(
                (s) =>
                    s.order_id.toLowerCase().includes(search_input_lowercase) ||
                    s.blnum.toLowerCase().includes(search_input_lowercase) ||
                    s.shipper_city.toLowerCase().includes(search_input_lowercase) ||
                    s.shipper_state.includes(search_input) ||
                    s.dest_city.toLowerCase().includes(search_input_lowercase) ||
                    s.dest_state.includes(search_input) ||
                    s.tractor_id.toLowerCase().includes(search_input_lowercase) ||
                    s.trailer_id.toLowerCase().includes(search_input_lowercase),
            );
        }
        //#endregion Filter

        const sticky_style = { position: 'sticky', top: 0 };

        const green_check = (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#428e6b"
                className="ps-1 pb-1"
                viewBox="0 0 16 16"
                role="graphics-symbol img"
            >
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
            </svg>
        );

        const red_x = (
            <label className="text-danger ps-1" style={{ fontWeight: '700' }}>
                X
            </label>
        );

        return (
            <Card className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between">
                    <Form.Control
                        placeholder="Search for an order..."
                        value={search_input}
                        onChange={(e) => this.setState({ search_input: e.target.value })}
                        style={{ fontSize: '70%', position: 'sticky', top: 0, border: 'none' }}
                    />
                    <Button
                        variant="outline-secondary"
                        onClick={() => this.setState({ search_input: '' })}
                        className="border-0"
                        size="sm"
                        title="Clear Search"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-x-lg"
                            viewBox="0 0 16 16"
                            role="graphics-symbol img"
                        >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </Button>
                    <Button
                        variant="outline-success"
                        onClick={() => this.exportToCSV(current_orders)}
                        className="border-0"
                        size="sm"
                        title="Export to CSV"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-filetype-csv"
                            viewBox="0 0 16 16"
                            role="graphics-symbol img"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"
                            />
                        </svg>
                    </Button>
                </div>

                <div className="d-flex flex-column justify-content-center">
                    <Table size="sm" style={{ fontSize: '0.67rem' }}>
                        <thead className="" style={sticky_style}>
                            <th
                                className="p-1"
                                onClick={() => this.setSort('id')}
                                onKeyDown={() => this.setSort('id')}
                                style={sticky_style}
                            >
                                <label className="text-dark p-0">
                                    <bdi className="pe-1">Order #</bdi>
                                    {this.props.sort_field === 'id' ? divider_chevron : null}
                                </label>
                            </th>
                            <th
                                className="p-1"
                                onClick={() => this.setSort('bol')}
                                onKeyDown={() => this.setSort('bol')}
                            >
                                <label className="text-dark p-0">
                                    <bdi className="pe-1">BOL #</bdi>
                                    {this.props.sort_field === 'bol' ? divider_chevron : null}
                                </label>
                            </th>
                            <th
                                className="p-1"
                                onClick={() => this.setSort('origin')}
                                onKeyDown={() => this.setSort('origin')}
                            >
                                <label className="text-dark p-0">
                                    <bdi className="pe-1">Origin</bdi>
                                    {this.props.sort_field === 'origin' ? divider_chevron : null}
                                </label>
                            </th>
                            <th
                                className="p-1"
                                onClick={() => this.setSort('destination')}
                                onKeyDown={() => this.setSort('destination')}
                            >
                                <label className="text-dark p-0">
                                    <bdi className="pe-1">Destination</bdi>
                                    {this.props.sort_field === 'destination' ? divider_chevron : null}
                                </label>
                            </th>
                            <th
                                className="p-1"
                                onClick={() => this.setSort('departed')}
                                onKeyDown={() => this.setSort('departed')}
                            >
                                <label className="text-dark p-0">
                                    <bdi className="pe-1">PU Date</bdi>
                                    {this.props.sort_field === 'departed' ? divider_chevron : null}
                                </label>
                            </th>
                            <th
                                className="p-1"
                                onClick={() => this.setSort('arrive')}
                                onKeyDown={() => this.setSort('arrive')}
                            >
                                <label className="text-dark p-0">
                                    <bdi className="pe-1">DEL Date</bdi>
                                    {this.props.sort_field === 'arrive' ? divider_chevron : null}
                                </label>
                            </th>
                            <th className="p-1">
                                <label className="text-dark p-0" />
                            </th>
                        </thead>
                        <tbody className="bg-white">
                            {current_orders.map((d) => (
                                <tr
                                    key={d.id}
                                    className={this.props.selected_order === d.order_id ? 'selected-row ' : 'tr'}
                                    onClick={() => this.props.update_selected_order(d)}
                                    onKeyDown={() => this.props.update_selected_order(d)}
                                >
                                    <td>{d.order_id}</td>
                                    <td>{d.blnum}</td>
                                    <td style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                        {d.shipper_city ? `${d.shipper_city}, ${d.shipper_state}` : '-'}
                                    </td>
                                    <td style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                        {d.dest_city ? `${d.dest_city}, ${d.dest_state}` : '-'}
                                    </td>
                                    <td style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                        <bdi>
                                            {`${fetchFunction.parseToDatetime(d.origin_arrival)} (${d.shipper_timezone})`}
                                        </bdi>
                                        {d.on_time === 'danger' && d.stop_type === 'PU' ? red_x : green_check}
                                    </td>
                                    <td style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                        <bdi>
                                            {`${fetchFunction.parseToDatetime(d.destination_arrival)} (${d.dest_timezone})`}
                                        </bdi>
                                        {d.on_time === 'danger' && d.stop_type === 'SO' ? red_x : green_check}
                                    </td>
                                    <td style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                        <div className="d-flex flex-row justify-content-between p-1">
                                            {this.checkTempFlag(d)}
                                            {this.checkTrailer(d)}
                                            {this.checkTractor(d)}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Card>
        );
    }

    exportToCSV(csvData) {
        const status = this.props.menu2;
        const timestamp = format(new Date(), 'MM-dd-yyyy HH:mm:ss');
        let fileName = '';
        if (status === 'A') {
            fileName = `available_orders_${timestamp}`;
        } else if (status === 'D') {
            fileName = `delivered_orders_${timestamp}`;
        } else if (status === 'P') {
            fileName = `in_progress_orders_${timestamp}`;
        } else {
            fileName = `all_orders_${timestamp}`;
        }

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
}
