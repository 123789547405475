import React, { Component } from 'react';
import { Alert, Badge, Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import * as fetchFunction from './Functions/FetchFunctions';
export class RouteDetails extends Component {
    static displayName = RouteDetails.name;
    constructor(props) {
        super(props);
        this.state = {
            tracking_type: 'tractor',
            show_badge: '',
        };
    }

    render() {
        const { tracking_type, show_badge } = this.state;

        const route_table = this.props.stops_loaded ? (
            <Table style={{ fontSize: '0.67rem' }} className="rounded" size="sm">
                <thead className="border border-2">
                    <th className="p-1">Order #</th>
                    <th className="p-1">Stop Type</th>
                    <th className="p-1">Status</th>
                    <th className="p-1">Location</th>
                    <th className="p-1">Arrival Date</th>
                    <th className="p-1">Departure Date</th>
                </thead>
                <tbody className="bg-white">
                    {this.props.stops.map((d) => (
                        <tr key={d.id} className="border-start border-end">
                            <td>
                                <label onClick={() => console.log(d)} onKeyDown={() => console.log(d)}>
                                    {d.order_id}
                                </label>
                            </td>
                            <td>
                                <label>{d.__typeDescr}</label>
                            </td>
                            <td
                                onClick={() => this.setState({ show_badge: show_badge === d.id ? '' : d.id })}
                                onKeyDown={() => this.setState({ show_badge: show_badge === d.id ? '' : d.id })}
                            >
                                {show_badge !== d.id ? (
                                    <label
                                        title="See Scheduled Time"
                                        className={` text-${d.on_time} border border-2  p-0 m-0 text-center border-${d.on_time}`}
                                        style={{
                                            fontSize: '0.55rem',
                                            fontWeight: 'bolder',
                                            width: '4.25rem',
                                            letterSpacing: '0.03rem',
                                            borderRadius: '0.4rem',
                                        }}
                                    >
                                        {d.on_time_status}
                                    </label>
                                ) : (
                                    <label
                                        className={`py-2 text-${d.on_time}`}
                                        style={{ fontWeight: 'bolder', letterSpacing: '0.02rem' }}
                                    >
                                        {fetchFunction.parseUTCDatetime(d.sched_arrive_early)}
                                    </label>
                                )}
                            </td>
                            <td>
                                <label className=" d-flex flex-column">
                                    <small>{`${d.city_name}, ${d.state}`}</small>
                                </label>
                            </td>

                            <td>
                                <label style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                    {d.actual_arrival ? fetchFunction.parseUTCDatetime(d.actual_arrival) : '-'}
                                </label>
                            </td>
                            <td className="">
                                <label style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                    {d.actual_departure ? fetchFunction.parseUTCDatetime(d.actual_departure) : '-'}
                                </label>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        ) : (
            <Alert className="text-center">Loading...</Alert>
        );

        return (
            <Card style={{ fontSize: '90%', height: '100%' }}>
                <Container>
                    <Row className="d-flex flex-row justify-content-between px-3 pt-3 ">
                        <Col lg={4} sm={12} className="d-flex flex-row justify-content-start p-0">
                            <h6 className="align-self-center py-1 m-0">Route Details</h6>
                        </Col>
                        <Col lg={8} sm={12} className="d-flex flex-row justify-content-end p-0" />
                    </Row>
                </Container>
                <hr className="mt-2" />
                <div className="px-3 py-1">{route_table}</div>
            </Card>
        );
    }
}
