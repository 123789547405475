import { addHours, format } from 'date-fns';
import React, { Component } from 'react';
import { Alert, Button, Card, CloseButton, Col, Container, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';

export class CommentsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            new_comment: {
                id: 0,
                order_id: this.props.order_id,
                comment: '',
                entered_user: this.props.user ? this.props.user.name : '',
                entered_date: format(new Date(), 'MM-dd-yyyy HH:mm'),
            },
            button_clicked: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange = (e) => {
        const current_comment = this.state.new_comment;
        current_comment[e.target.name] = e.target.value;
        this.setState({
            new_comment: current_comment,
        });
    };

    async postComment() {
        this.setState({ button_clicked: true });
        try {
            const current_comment = this.state.new_comment;
            current_comment.order_id = this.props.order_id;
            const options = {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify(current_comment),
            };
            await fetch('Comments', options)
                .then((res) => {
                    return res.text();
                })
                .then((data) => {
                    const current_comments = this.props.comments;
                    current_comments.push(current_comment);
                    this.props.update_comments(JSON.stringify(current_comments));
                    this.setState({
                        button_clicked: false,
                        new_comment: {
                            id: 0,
                            order_id: this.props.order_id,
                            comment: '',
                            entered_user: this.props.user ? this.props.user.name : '',
                            entered_date: format(new Date(), 'MM-dd-yyyy HH:mm'),
                        },
                    });
                });
        } catch (e) {
            console.log(e);
            this.setState({
                button_clicked: false,
                new_comment: {
                    id: 0,
                    order_id: this.props.order_id,
                    comment: '',
                    entered_user: this.props.user ? this.props.user.name : '',
                    entered_date: format(new Date(), 'MM-dd-yyyy HH:mm'),
                },
            });
        }
    }

    render() {
        const { new_comment, button_clicked } = this.state;
        const comments = [];
        for (let i = 0; i < this.props.comments.length; i++) {
            if (this.props.comments[i].order_id === this.props.order_id) {
                comments.push(this.props.comments[i]);
            }
        }

        return (
            <div className="d-flex flex-column w-100 ">
                <strong className="ps-2">Comments</strong>
                <div className="px-2">
                    <Table size="sm" bordered className="bg-white">
                        <tbody>
                            {comments.map((d, index) => (
                                <tr key={d.id} className="alternate-rows">
                                    <td className=" border-end border-start">
                                        <div className="d-flex flex-column">
                                            <bdi> {d.comment}</bdi>
                                            <small className="mt-2">
                                                Entered by: {d.entered_user} on {d.entered_date}
                                            </small>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="p-2 pt-0">
                    <Form.Control
                        placeholder="Enter new comment..."
                        as="textarea"
                        rows={3}
                        name="comment"
                        value={new_comment.comment}
                        style={{ fontSize: '0.7rem', resize: 'none' }}
                        onChange={this.handleInputChange}
                    />
                </div>
                <div className="p-2">
                    <Button
                        variant={button_clicked ? 'outline-success' : 'success'}
                        disabled={button_clicked}
                        style={{ fontSize: '0.7rem' }}
                        size="sm"
                        className="btn-op70 align-self-center"
                        onClick={() => this.postComment()}
                    >
                        {button_clicked ? 'Adding...' : '+ Add Comment'}
                    </Button>
                </div>
            </div>
        );
    }
}
