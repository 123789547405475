export const msalConfig = {
    auth: {
        clientId: '4842cbc9-987d-4a72-be03-838973260952',
        authority:
            'https://scotlynncustomers.b2clogin.com/tfp/scotlynncustomers.onmicrosoft.com/B2C_1_signup_and_signin',
        redirectUri: '/portal',
        navigateToLoginRequestUrl: false,
        knownAuthorities: ['scotlynncustomers.b2clogin.com'],
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const protectedResources = {
    adminResources: {
        scopes: ['user.read'],
    },
};

export const loginRequest = {
    scopes: ['openid', 'offline_access', 'profile'],
};

export const appRoles = {
    Admin: 'title.Admin',
    Credit: 'title.Credit',
    LAM: 'title.LAM',
    GM: 'title.GM',
};

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const graphConfigPhoto = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
};
